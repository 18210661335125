String.prototype.format = function (args) {
  let formatted = this;
  for (let i = 0; i < args.length; i++) {
    let regexp = new RegExp("\\{" + i + "\\}", "gi");
    formatted = formatted.replace(regexp, t(args[i]));
  }
  return formatted;
};

export function truncateString(str, num) {
  if (str == null || str == undefined) return str;
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

export function isPageNew() {
  if (window.performance) {
    var navEntries = window.performance.getEntriesByType("navigation");
    if (navEntries.length > 0 && navEntries[0].type === "back_forward") {
      console.log(
        "isPageNew: As per API lv2, this page is load from back/forward"
      );
      return false;
    } else if (
      window.performance.navigation &&
      window.performance.navigation.type ==
        window.performance.navigation.TYPE_BACK_FORWARD
    ) {
      console.log(
        "isPageNew: As per API lv1, this page is load from back/forward"
      );
      return false;
    } else {
      console.log("isPageNew: This is normal page load");
      return true;
    }
  } else {
    console.log(
      "isPageNew: Unfortunately, your browser doesn't support this API"
    );
    return true;
  }
}

export function toMomentObj(date_time) {
  if (date_time == undefined || date_time == null) return moment();
  return moment(date_time);
}

export function toMoment(date_time) {
  return moment(date_time).fromNow();
}

export function t(str, replace = []) {
  if (str == null || str == undefined) {
    return str;
  }
  str = str.toString();
  if (
    frappe._messages[str.toLowerCase()] == undefined &&
    frappe._messages[str] == undefined
  ) {
    if (replace.length) return str.format(replace);
    return str;
  }
  var translation_string = frappe._messages[str];
  if (translation_string == undefined) {
    translation_string = frappe._messages[str.toLowerCase()];
  }
  if (replace.length) return translation_string.format(replace);
  return translation_string;
}

export function exceptionToMap(exceptionJSON) {
  var exceptionMAP = JSON.parse(exceptionJSON);
  for (var i = 0; i < exceptionMAP.length; i++) {
    exceptionMAP[i] = JSON.parse(exceptionMAP[i]);
  }
  return exceptionMAP;
}

export function showException(e, callback = null) {
  var exceptions = ""
  if(typeof e === 'string' || e instanceof String){
    exceptions = [{"message":e}]
  }else{
    exceptions = exceptionToMap(e.responseJSON._server_messages);
  }
  bootbox.alert({
    title: t("Error"),
    message:
      `<div class="alert alert-danger" role="alert">
            ` +
            t(exceptions[0].message) +
      `
            </div>`,
    size: "large",
    buttons: {
      ok: {
        label: t("Ok"),
        className: "default-btn",
      },
    },
    // callback: (results) => results?this.save_property():console.log("cancel"),
    callback: callback,
  });
  return exceptions;
}

export function toMomentStringTranslationFromNow(moment_string) {
  var matches = moment_string.match(/(\d+)/);
  if (matches) {
    return t(moment_string.replace(/[0-9]+/g, "{0}"), [matches[0]]);
  }
  return t(moment_string);
}

export function toMomentString(date_time) {
  return toMoment(date_time);
}

export function toMomentStringTranslation(date_time) {
  moment_string = toMomentString(date_time);
  var matches = moment_string.match(/(\d+)/);
  if (matches) {
    return t(moment_string.replace(/[0-9]+/g, "{0}"), [matches[0]]);
  }
  return t(moment_string);
}

export function showSuccess(message, callback = null) {
  bootbox.alert({
    title: t("Success"),
    message:
      `<div class="alert alert-success" role="alert">
            ` +
      t(message) +
      `
            </div>`,
    size: "large",
    buttons: {
      ok: {
        label: t("Ok"),
        className: "default-btn",
      },
    },
    // callback: (results) => results?this.save_property():console.log("cancel"),
    callback: callback,
  });
}

export function upload_file(
  file,
  i,
  doctype,
  docname,
  fieldname = null,
  method = null,
  attach_doc_image = null
) {
  // var file = new File([filer], "test.jpg",{ type: "image/png" });
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("loadstart", (e) => {
      file.uploading = true;
    });
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        file.progress = e.loaded;
        file.total = e.total;
      }
    });
    xhr.upload.addEventListener("load", (e) => {
      file.uploading = false;
      resolve();
    });
    xhr.addEventListener("error", (e) => {
      file.failed = true;
      reject();
    });
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          file.request_succeeded = true;
          let r = null;
          let file_doc = null;
          try {
            r = JSON.parse(xhr.responseText);
            if (r.message.doctype === "File") {
              file_doc = r.message;
            }
          } catch (e) {
            r = xhr.responseText;
          }
          file.doc = file_doc;
          // if (i == this.files.length - 1 && this.files.every(file => file.request_succeeded)) {
          //   this.close_dialog = true;
          // }
        } else if (xhr.status === 403) {
          file.failed = true;
          let response = JSON.parse(xhr.responseText);
          file.error_message = `Not permitted. ${
            response._error_message || ""
          }`;
        } else if (xhr.status === 413) {
          file.failed = true;
          file.error_message = "Size exceeds the maximum allowed file size.";
        } else {
          file.failed = true;
          file.error_message =
            xhr.status === 0
              ? "XMLHttpRequest Error"
              : `${xhr.status} : ${xhr.statusText}`;
          let error = null;
          try {
            error = JSON.parse(xhr.responseText);
          } catch (e) {
            // pass
          }
          frappe.request.cleanup({}, error);
        }
      }
    };
    xhr.open("POST", "/api/method/upload_file", true);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("X-Frappe-CSRF-Token", frappe.csrf_token);
    let form_data = new FormData();
    if (file.file_obj) {
      form_data.append("file", file.file_obj, file.name);
    }
    form_data.append("is_private", +file.private);
    form_data.append("folder", "Home");
    if (file.file_url) {
      form_data.append("file_url", file.file_url);
    }
    file.file_name = file.name;
    if (file.file_name) {
      form_data.append("file_name", file.file_name);
    }
    form_data.append("doctype", doctype);
    form_data.append("docname", docname);
    if (fieldname) {
      form_data.append("fieldname", fieldname);
    }
    if (method) {
      form_data.append("method", method);
    }
    if (file.optimize) {
      form_data.append("optimize", true);
    }
    if (attach_doc_image) {
      form_data.append("max_width", 200);
      form_data.append("max_height", 200);
    }
    xhr.send(form_data);
  });
}

export function nFormatter(num, digits = 1) {
  num = parseFloat(num.toString());
  const lookup = [
    {
      value: 1,
      symbol: "",
    },
    {
      value: 1e3,
      symbol: frappe.lang_dir == "ltr" ? "k" : "الف",
    },
    {
      value: 1e6,
      symbol: frappe.lang_dir == "ltr" ? "M" : "م",
    },
    {
      value: 1e9,
      symbol: frappe.lang_dir == "ltr" ? "B" : "ب",
    },
    {
      value: 1e12,
      symbol: frappe.lang_dir == "ltr" ? "T" : "ت",
    },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + " " + item.symbol
    : "0";
}
